import storageProxy from 'zp-lib/src/storage/proxy';

const STORAGE_KEY = 'sxt';

const storage = storageProxy(STORAGE_KEY);

const getToken = () => storage.token;

function removeToken() {
  delete storage.token;
  delete storage.user;
}

function removeSave(name) {
  const { userName } = storage.save || {};

  if (userName === name) {
    delete storage.save;
  }
}

export default storage;
export {
  getToken,
  removeToken,
  removeSave,
};
