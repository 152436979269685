import request from "@/utils/request";
import { setAppName } from "@/utils/common";
const ipBaseURL = "https://test-huanxinxiang.ai-me.cn/huanxinxiang-service/";
const appName = setAppName();
// const appName = "huanxinxiang-zhengxing";

// 发送短信验证码
export const getCode = (data) =>
  request({
    url: "/huanxinxiang-service/v1/code/",
    method: "post",
    headers: {
      "App-Name": appName,
    },
    // ipBaseURL,
    data,
  });

// 登录
export const login = (data) =>
  request({
    url: "/huanxinxiang-service/v1/login/",
    method: "post",
    headers: {
      "App-Name": appName,
    },
    // ipBaseURL,
    data,
  });

// 根据openId登录
export const wechatLogin = (data) =>
  request({
    url: "/huanxinxiang-service/v1/wechat-login/",
    method: "post",
    headers: {
      "App-Name": appName,
    },
    // ipBaseURL,
    data,
  });

// 获取微信openid
export const getOpenId = (data) =>
  request({
    url: "/huanxinxiang-service/v1/get-wechat-open-id/",
    method: "post",
    headers: {
      "App-Name": appName,
    },
    // ipBaseURL,
    data,
  });

// 获取用户信息
export const getUser = (Token) =>
  request({
    url: "/huanxinxiang-service/v1/get-user/",
    method: "post",
    headers: {
      "App-Name": appName,
      Token: Token,
    },
    // ipBaseURL,
  });

  // 获取权益列表
export const equitylist = (data) => request({
  url: '/huanxinxiang-service/v1/get-item/',
  method: 'post',
  headers: {
    "App-Name": appName,
  },
  // ipBaseURL,
  data,
});

// 领取权益
export const claimBenefits = (Token, data) => request({
  url: '/huanxinxiang-service/v1/buy-item/',
  method: 'post',
  headers: {
    "Token": Token,
    "App-Name": appName,
  },
  // ipBaseURL,
  data,
});

// 领取记录
export const getRecord = (Token, data) => request({
  url: '/huanxinxiang-service/v1/get-record/',
  method: 'post',
  headers: {
    "Token": Token,
    "App-Name": appName,
  },
  data,
  // ipBaseURL,

});

// app登录
export const appLogin = ( data) => request({
  url: '/huanxinxiang-service/v1/app-login/',
  method: 'post',
  headers: {
    "App-Name": appName,
  },
  data,
});

// 支付宝签约
export const alipayPay = (data) =>
  request({
    url: "/huanxinxiang-service/v1/alipay-pay/",
    method: "post",
    data,
    headers: {
      "App-Name": appName,
    },
  });
