import storage, { removeToken } from "@/utils/auth";
import { Dialog, Toast } from "vant";
import { wechatLogin, getOpenId } from "@/api/tao.js";
import { isWeixin } from "@/utils/common";
import router from "@/router";

const getDefaultState = () => ({
  phone: storage.phone || "",
  token: storage.token || "",
});

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_DATA(state, status) {
    const { phone, token } = status;
    state.phone = phone;
    storage.phone = phone;

    state.token = token;
    storage.token = token;
  },
};

const actions = {
  async login({ commit }, userInfo) {
    console.log(router);

    if (!isWeixin()) {
      router.replace({
        path: "/tao/equityCenter/login/login",
      });
    } else {
      const urlCode = new URLSearchParams(window.location.search).get("code");
      if (!urlCode) {
        if (
          router.app._route.fullPath == "/tao/equityCenter/homePage" &&
          !code
        ) {
          let redirectUri = encodeURIComponent(`${location.href}`);
          const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxfe906cab8720b9e1&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
          window.location.replace(url);
        }
        return;
      }
      Toast.loading({
        duration: 0,
        forbidClick: true,
        mask: true,
        message: "加载中...",
      });
      const {
        wechat_open_id = "",
        message = "",
        code = 0,
      } = await getOpenId({ login_code: urlCode });
      if (code != 0) {
        Toast.clear();
        Dialog.alert({
          title: message,
        });
      } else {
        const res = await wechatLogin({ wechat_open_id: wechat_open_id });
        if (res.code == 0) {
          commit("SET_DATA", {
            phone: res.phone_number,
            token: res.token,
          });
        } else {
          router.replace({
            path: "/tao/equityCenter/login/login",
            query: { wechat_open_id: wechat_open_id },
          });
        }
      }
      Toast.clear();
    }
  },
};

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations,
  actions,
};
