import request from '@/utils/request';
const appName = "huanxinxiang-taiyue";

// 发送短信
export const code = (data) => request({
  url: '/huanxinxiang-service/v1/code/',
  method: 'post',
  headers: {
    "App-Name": appName,
  },
  data,
});

// 短信验证码登录
export const login = (data) => request({
  url: '/huanxinxiang-service/v1/login/',
  method: 'post',
  headers: {
    "App-Name": appName,
  },
  data,
});

// 获取微信openid
export const getWechatopenid = (data) => request({
  url: '/huanxinxiang-service/v1/get-wechat-open-id/',
  method: 'post',
  headers: {
    "App-Name": appName,
  },
  data,
});

// 微信登录
export const wechatLogin = (data) => request({
  url: '/huanxinxiang-service/v1/wechat-login/',
  method: 'post',
  headers: {
    "App-Name": appName,
  },
  data,
});

// 获取用户信息 
export const getUser = (token) => request({
  url: '/huanxinxiang-service/v1/get-user/',
  method: 'post',
  headers: {
    "App-Name": appName,
    Token : token,
  },
});

// 权益列表
export const equityList = (data) => request({
  url: '/huanxinxiang-service/v1/get-item/',
  method: 'post',
  headers: {
    "App-Name": appName,
  },
  data,
});
// 领取权益
export const equityReceive = (Token, data) =>
  request({
    url: "/huanxinxiang-service/v1/buy-item/",
    method: "post",
    headers: {
      Token,
      "App-Name": appName,
    },
    data,
});
// 记录
export const record = (Token, data) =>
  request({
    url: "/huanxinxiang-service/v1/get-record/",
    method: "post",
    headers: {
      Token,
      "App-Name": appName,
    },
    data,
});
// 发起解约
export const alipayUnsign = (Token, data) =>
  request({
    url: "/huanxinxiang-service/v1/alipay-unsign/",
    method: "post",
    headers: {
      Token,
      "App-Name": appName,
    },
    data,
});
// 可退款订单
export const getRefundable = (Token) =>
  request({
    url: "/huanxinxiang-service/v1/get-refundable/",
    method: "post",
    headers: {
      Token,
      "App-Name": appName,
    },
});
// 退款 
export const refundSingle = (Token, data) =>
  request({
    url: "/huanxinxiang-service/v1/refund-single/",
    method: "post",
    headers: {
      Token,
      "App-Name": appName,
    },
    data,
});

