import storage, { removeToken } from "@/utils/auth";
import { getUser } from "@/api/tao.js";
import router from "@/router";

const getDefaultState = () => ({
  member_phone_number: storage.member_phone_number || "",
  member_head_img: storage.member_head_img || "",
  member_token: storage.member_token || "",
  member_user_info: {},
});

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_DATA(state, status) {
    Object.assign(state, status);

    storage.member_phone_number = state.member_phone_number;
    storage.member_head_img = state.member_head_img;
    storage.member_token = state.member_token;
  },
  SET_USER_INFO(state, status) {
    state.member_user_info = status;
  },
};

const actions = {
  async getUserInfo({ state, commit }) {
    try {
      const res = await getUser(state.member_token);

      if (res.code == 0) {
        commit("SET_USER_INFO", res.user_info);
      }
    } catch (error) {
      router.replace("/tao/memberBenefits/notOpened/notOpened");
    }
  },
};

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations,
  actions,
};
