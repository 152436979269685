import storage, { removeToken } from "@/utils/auth";
const getters = {
  token: (state) => state.sxt.token,
  phone: (state) => state.sxt.phone,
  member_phone_number: (state) => state.memberBenefits.member_phone_number,
  member_head_img: (state) => state.memberBenefits.member_head_img,
  member_token: (state) => state.memberBenefits.member_token,
  member_user_info: (state) => state.memberBenefits.member_user_info,
  carOwner_zhengxing_token: (state) => state.carOwner_zhengxing.token,
  carPhone_number: (state) => state.carOwner_zhengxing.carPhone_number,
  carOwner_zhengxing_user_info: (state) => state.carOwner_zhengxing.user_info,
  taiyue_token: (state) => state.taiyue.token,
  taiyue_phonenumber: (state) => state.taiyue.taiyue_phonenumber,
  taiyue_user_info: (state) => state.taiyue.user_info,
  taiyue_wechat_open_id: (state) => state.taiyue.wechat_open_id,

  lky_wechat_open_id: (state) => state.lky.wechat_open_id,
  // lky_phonenumber: (state) => state.lky.lky_phonenumber,
  lky_token: (state) => state.lky.token,
  lky_user_info: (state) => state.lky.user_info,
  changXueSupreme_token: (state) => state.changXueSupreme.token,
  changXueSupreme_info: (state) => state.changXueSupreme.info,
  changXueSupreme_productNo: (state) => state.changXueSupreme.productNo,
};

export default getters;
