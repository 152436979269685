import CryptoJS from "crypto-js";

// 判断是否微信环境
export const isWeixin = () => {
  if (
    navigator &&
    navigator.userAgent &&
    navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1
  ) {
    return true;
  }
  return false;
};

export const isDevEnv = () => {
  if (process.env.NODE_ENV === "development") {
    return true
  } else {
    return false
  }
};

export const isAlipayEnvironment = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /alipayclient/i.test(userAgent);
};

const appNames = {
  // 秋末
  "qiumo.net.cn": "huanxinxiang",
  // 海络
  "hzhlxx.net.cn": "huanxinxiang-hailuo",
  // 铮行
  "zjzxkj.net.cn": "huanxinxiang-zhengxing",
  // 秋末
  "szyxb299.xwtec.cn": "huanxinxiang",
  // 海络
  "szyxb311.xwtec.cn": "huanxinxiang-hailuo",
  // 云先
  "h5.aliyunxian.com.cn": "huanxinxiang-yunxian",
  // 数创
  "qy.scyp.cc": "huanxinxiang-shuchuang",
  // 广通
  "qiumo.freelynetgt.com": "huanxinxiang-guangtong",
  // 艾基生
  "yilu-shua.aigc-x.cn": "huanxinxiang-aijisheng",
  // 艾基生
  "aigc-x.cn": "huanxinxiang-aijisheng",
  // 泰岳
  "sd.taiyuetech.cn": "huanxinxiang-taiyue",
  // 莱可优
  "ai-me.cn": "huanxinxiang-laikeyou",
  // 卉墨
  "baobei.hzhuimokj.cn": "huanxinxiang-huimo",
  // 鹤游
  "baobei.hzheyoukj.cn": "huanxinxiang-heyou",
  // 帖芯
  "sd.dasitan.cn": "huanxinxiang-dasitan",
  // 哒丝坦
  "sd.tie-xin.com.cn": "huanxinxiang-tiexin",
};

// 设置主体
export const setAppName = () => {
  const hostname = window.location.hostname;
  for (let domain in appNames) {
    if (hostname.includes(domain)) {
      console.log("appNames[domain]=", appNames[domain]);
      return appNames[domain];
    }
  }
  return "";
};

// 观正签名
export const generateSign = (
  appCode = "GUANZHENG",
  appKey = "*OF*&(BNHOP0"
) => {
  const nowDate = new Date();
  const timesap = nowDate.getTime();

  const authData = appCode + appKey + timesap;
  // 使用CryptoJS.MD5生成MD5哈希
  let sign = CryptoJS.MD5(authData).toString(CryptoJS.enc.Hex);
  // 转换为大写
  sign = sign.toUpperCase();
  return {
    sign,
    timesap,
    appCode,
  };
};

// 流量果
// /*
// 广告位名称：ad_position_id
// phone：手机号
// product_name： 原投放的产品名称
// fail_message: 失败原因
// */
let llgSDK;
const loadSDK = async () => {
  if (!llgSDK) {
    const { default: sdk } = await import('@/utils/liuliangguoSDKpro_min');
    llgSDK = sdk;
  }
  return llgSDK;
};
export const liuliangguo = async (
  ad_position_id = "102265",
  phone = "",
  product_name = "原投放的产品名称",
  fail_message = "用户办理原产品的失败原因"
) => {
  const sdk = await loadSDK();

  let params = {
    app_key: "LLG10005574",
    app_secret: "8a6f7c3f39e9ee88127d96b354b63c55",
    ad_position_id,
    phone,
    fail_message,
    product_name,
  };

  console.log('params--', { ad_position_id, phone });

  sdk && sdk.getAdAndShow(params, function (res) {
    console.log(res);
  });
};

// 今领
let JuDuoDuoSDKLoaded = false;
let JuDuoDuoSDK;

export const JuDuoDuo = async (ad_handle_id = '100023', phone = "", product_name = "", fail_message = "") => {
  // // 动态加载 SDK
  if (!JuDuoDuoSDKLoaded) {
    // 动态加载 SDK
    const scriptUrl = 'https://cdnoss.guguatv.com/js/JuDuoDuoSDK.min.js';
    await new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = scriptUrl;
      script.onload = () => {
        JuDuoDuoSDK = window.JuDuoDuoSDK;
        JuDuoDuoSDKLoaded = true;
        resolve();
      };
      script.onerror = () => reject(new Error('Failed to load JuDuoDuoSDK'));
      document.head.appendChild(script);
    });
  }

  const params = {
    app_id: "a1b820000b21d041793116ab8125d3e3",
    app_key: "ff52cf64e5b4ff603bb122db03e7be83",
    ad_handle_id,
    phone,
    product_name,
    fail_message,
  };

  console.log('params--', params);

  JuDuoDuoSDK && JuDuoDuoSDK.getAdAndShow(params, function (res) {
    console.log(res);
    if (!res.is_success) {
      console.log(res.message);
      // 获取广告失败执行的动作
    }
  });
};

// 枣米糖
export const ZaoMiTang = async (url, callback) => {
  var script = document.createElement("script");
  script.type = "text/javascript";
  script.onload = function () {
    callback();
  };
  script.src = url;
  document.head.appendChild(script);
};