import storage, { removeToken } from "@/utils/auth";
import { getUser } from "@/api/taiyue/receive.js";
import router from "@/router";

const getDefaultState = () => ({
  token: storage.token || "",
  taiyue_phonenumber: storage.taiyue_phonenumber || "",
  user_info: storage.user_info || {
    phone_number: "",
    available_vip: [],
    exchanged_items: [],
  },
  wechat_open_id: storage.wechat_open_id || "",
});

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN(state, status) {
    state.token = status;
    storage.token = state.token;
  },
  SET_WECHAT_OPEN_ID(state, status) {
    state.wechat_open_id = status;
    storage.wechat_open_id = state.wechat_open_id;
  },
  SET_PHONE_NUMBER(state, status) {
    state.taiyue_phonenumber = status;
    storage.taiyue_phonenumber = state.taiyue_phonenumber;
    console.log(state)
  },
  SET_USER_INFO(state, status) {
    state.user_info = status;
    storage.user_info = state.user_info;
  },
};

const actions = {
  async getUserInfo({ state, commit }) {
    try {
      const res = await getUser(state.token);
      console.log('res.user_info====', res.user_info)
      if (res.code == 0) {
        commit("SET_USER_INFO", res.user_info);
      }
    } catch (error) {
      router.replace("/fz_taiyue/receive/login");
    }
  },
};

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations,
  actions,
};
