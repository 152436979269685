import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/index",
    component: () => import("@/views/index.vue"),
    meta: {
      title: "随心淘",
    },
  },
  {
    path: "/monthlyHotTopicsNTo2_HaiLuo",
    component: () => import("@/views/monthlyHotTopicsNTo2_HaiLuo/index.vue"),
    meta: {
      title: "欢心享",
    },
  },
  {
    path: "/monthlyHotTopicsNTo2_HaiLuo/receive",
    component: () =>
      import("@/views/monthlyHotTopicsNTo2_HaiLuo/receive/index.vue"),
    meta: {
      title: "欢心享",
    },
  },
  {
    path: "/monthlyHotTopicsNTo2_HaiLuo/receive/login",
    component: () =>
      import("@/views/monthlyHotTopicsNTo2_HaiLuo/receive/login.vue"),
    meta: {
      title: "欢心享",
    },
  },
  {
    path: "*",
    component: () => import("@/views/404.vue"),
    meta: {
      title: "404",
    },
  },
  {
    path: "/tao/equityCenter/zfblogin/login",
    component: () => import("@/views/tao/equityCenter/login.vue"),
    meta: {
      title: "随心淘",
    },
  },
  {
    path: "/tao/equityCenter/homePage",
    component: () => import("@/views/tao/equityCenter/homePage.vue"),
    meta: {
      title: "随心淘",
    },
  },
  {
    path: "/tao/equityCenter/record",
    component: () => import("@/views/tao/equityCenter/record.vue"),
    meta: {
      title: "随心淘",
    },
  },

  {
    path: "/refund",
    component: () => import("@/views/refund/index.vue"),
    meta: {
      title: " ",
    },
  },
  {
    path: "/monthlyHotTopicsNTo2_HaiLuo/test",
    component: () => import("@/views/monthlyHotTopicsNTo2_HaiLuo/test.vue"),
    meta: {
      title: "欢心享",
    },
  },
  {
    path: "/suixinxiang25",
    component: () => import("@/views/suixinxiang25/index.vue"),
    meta: {
      title: "随心享",
    },
  },
  {
    path: "/monthlyHotTopicsNTo2_GuangTong",
    component: () => import("@/views/monthlyHotTopicsNTo2_GuangTong/index.vue"),
    meta: {
      title: "随心享",
    },
  },
  {
    path: "/monthlyHotTopicsNTo2_GuangTong/activityRule",
    component: () =>
      import("@/views/monthlyHotTopicsNTo2_GuangTong/activityRule.vue"),
    meta: {
      title: "随心会员",
    },
  },
  {
    path: "/monthlyHotTopicsNTo2_YunXian",
    component: () => import("@/views/monthlyHotTopicsNTo2_YunXian/index.vue"),
    meta: {
      title: "随心会员",
    },
  },
  {
    path: "/monthlyHotTopicsNTo2_YunXian/rule",
    component: () => import("@/views/monthlyHotTopicsNTo2_YunXian/rule.vue"),
    meta: {
      title: "随心会员",
    },
  },
  {
    path: "/monthlyHotTopicsNTo2_YunXian/watermark",
    component: () =>
      import("@/views/monthlyHotTopicsNTo2_YunXian/watermark.vue"),
    meta: {
      title: "随心会员",
    },
  },
  {
    path: "/monthlyHotTopicsNTo2_YunXian/receive",
    component: () =>
      import("@/views/monthlyHotTopicsNTo2_YunXian/receive/index.vue"),
    meta: {
      title: "欢心享",
    },
  },
  {
    path: "/monthlyHotTopicsNTo2_zhengxing",
    component: () => import("@/views/monthlyHotTopicsNTo2_zhengxing/index.vue"),
    meta: {
      title: "随心享",
    },
  },
  {
    path: "/suixinxiang25/selfInvestment",
    component: () => import("@/views/suixinxiang25/selfInvestment.vue"),
    meta: {
      title: "随心享",
    },
  },
  {
    path: "/suixinxiang25/xiaoyang",
    component: () => import("@/views/suixinxiang25/xiaoyang.vue"),
    meta: {
      title: "随心享",
    },
  },
  {
    path: "/monthlyHotVipNTo2_zhengxing/index.vue",
    component: () => import("@/views/monthlyHotVipNTo2_zhengxing/index.vue"),
    meta: {
      title: "月月热门会员N选2",
    },
  },
  {
    path: "/monthlyHotVipNTo2_zhengxing/userRule.vue",
    component: () => import("@/views/monthlyHotVipNTo2_zhengxing/userRule.vue"),
    meta: {
      title: "月月热门会员N选2",
    },
  },
  {
    path: "/monthlyHotVipNTo2_zhengxing/activity.vue",
    component: () => import("@/views/monthlyHotVipNTo2_zhengxing/activity.vue"),
    meta: {
      title: "月月热门会员N选2",
    },
  },
  {
    path: "/petroChina",
    component: () => import("@/views/petroChina/index.vue"),
    meta: {
      title: "中国石油加油权益包",
    },
  },
  {
    path: "/foodSuiXinXiang",
    component: () => import("@/views/foodSuiXinXiang/index.vue"),
    meta: {
      title: "美食随心享",
    },
  },
  {
    path: "/tao/memberBenefits/notOpened/notOpened",
    component: () =>
      import("@/views/tao/memberBenefits/notOpened/notOpened.vue"),
    meta: {
      title: "随心淘会员",
    },
  },
  {
    path: "/tao/memberBenefits/notOpened/agreement/rules",
    component: () =>
      import("@/views/tao/memberBenefits/notOpened/agreement/rules.vue"),
    meta: {
      title: "活动规则",
    },
  },
  {
    path: "/tao/memberBenefits/notOpened/agreement/agreement",
    component: () =>
      import("@/views/tao/memberBenefits/notOpened/agreement/agreement.vue"),
    meta: {
      title: "会员服务协议",
    },
  },
  {
    path: "/tao/memberBenefits/notOpened/agreement/renew",
    component: () =>
      import("@/views/tao/memberBenefits/notOpened/agreement/renew.vue"),
    meta: {
      title: "自动续费",
    },
  },
  {
    path: "/tao/memberBenefits/opened/opened",
    component: () => import("@/views/tao/memberBenefits/opened/opened.vue"),
    meta: {
      title: "随心淘会员",
    },
  },
  {
    path: "/tao/memberBenefits/record/collection/collection",
    component: () =>
      import("@/views/tao/memberBenefits/record/collection/collection.vue"),
    meta: {
      title: "领取记录",
    },
  },
  {
    path: "/tao/memberBenefits/record/deduction/deduction",
    component: () =>
      import("@/views/tao/memberBenefits/record/deduction/deduction.vue"),
    meta: {
      title: "扣费记录",
    },
  },
  {
    path: "/lifeQuotient_zhengxing",
    component: () => import("@/views/lifeQuotient_zhengxing/index.vue"),
    meta: {
      title: "生活商超会",
    },
  },
  {
    path: "/carOwner_hailuo",
    component: () => import("@/views/carOwner_hailuo/index.vue"),
    meta: {
      title: "车主畅想惠",
    },
  },
  {
    path: "/carOwner_zhengxing",
    component: () => import("@/views/carOwner_zhengxing/index.vue"),
    meta: {
      title: "车主出行惠",
    },
  },
  {
    path: "/carOwner_zhengxing/receive/login",
    component: () => import("@/views/carOwner_zhengxing/receive/login.vue"),
    meta: {
      title: "登录",
    },
  },
  {
    path: "/carOwner_zhengxing/receive/homePage",
    component: () => import("@/views/carOwner_zhengxing/receive/homePage.vue"),
    meta: {
      title: "会员福利中心",
    },
  },
  {
    path: "/carOwner_zhengxing/receive/record",
    component: () => import("@/views/carOwner_zhengxing/receive/record.vue"),
    meta: {
      title: "领取记录",
    },
  },
  {
    path: "/carOwner_zhengxing/receive/membership",
    component: () =>
      import("@/views/carOwner_zhengxing/receive/membership.vue"),
    meta: {
      title: "车主会员",
    },
  },
  {
    path: "/carOwner",
    component: () => import("@/views/carOwner/index.vue"),
    meta: {
      title: "车主权益",
    },
  },
  {
    path: "/carOwner/red",
    component: () => import("@/views/carOwner/red.vue"),
    meta: {
      title: "车主权益",
    },
  },
  {
    path: "/carOwner_zhengxing/enjoy",
    component: () => import("@/views/carOwner_zhengxing/enjoy.vue"),
    meta: {
      title: "29.9车主畅享会员",
    },
  },
  {
    path: "/oneYuan_shuchuang/index",
    component: () => import("@/views/oneYuan_shuchuang/index.vue"),
    meta: {
      title: "会员月月领",
    },
  },
  {
    path: "/yilucamera",
    component: () => import("@/views/yilucamera/index.vue"),
    meta: {
      title: "一鹿相机",
    },
  },
  {
    path: "/orderManipulation_zhengxing",
    component: () =>
      import("@/views/recurringPayment_zhengxing/orderManipulation/index.vue"),
    meta: {
      title: "会员月月领",
    },
  },
  {
    path: "/suixinxiang0.01",
    component: () => import("@/views/suixinxiang0.01/index.vue"),
    meta: {
      title: "随心享会员",
    },
  },
  {
    path: "/yunmobile_GuangTong",
    component: () => import("@/views/yunmobile_GuangTong/index.vue"),
    meta: {
      title: "移动云手机",
    },
  },
  {
    path: "/yunmobile_GuangTong/watermark",
    component: () => import("@/views/yunmobile_GuangTong/watermark.vue"),
    meta: {
      title: "移动云手机",
    },
  },
  {
    path: "/yilucamera25",
    component: () => import("@/views/yilucamera/25.vue"),
    meta: {
      title: "一鹿相机",
    },
  },
  {
    path: "/yilucamera29.9",
    component: () => import("@/views/yilucamera/29.9.vue"),
    meta: {
      title: "一鹿相机",
    },
  },
  {
    path: "/yunmobile_GuangTong/lifeQuotient",
    component: () => import("@/views/yunmobile_GuangTong/lifeQuotient.vue"),
    meta: {
      title: "惠生活",
    },
  },
  {
    path: "/brushingOrders",
    component: () =>
      import("@/views/monthlyHotTopicsNTo2_aiJiSheng/brushingOrders.vue"),
    meta: {
      title: "会员月月领",
    },
  },
  {
    path: "/audiovisualSelection",
    component: () =>
      import("@/views/monthlyHotTopicsNTo2_aiJiSheng/audiovisualSelection.vue"),
    meta: {
      title: "视听甄选会员",
    },
  },
  {
    path: "/orderManipulation_zhengxing/onceThreeYuan",
    component: () =>
      import(
        "@/views/recurringPayment_zhengxing/orderManipulation/onceThreeYuan.vue"
      ),
    meta: {
      title: "会员月月领",
    },
  },
  {
    path: "/carOwner_zhengxing/changxiangVip",
    component: () => import("@/views/carOwner_zhengxing/changxiangVip.vue"),
    meta: {
      title: "车主畅享会员",
    },
  },
  {
    path: "/vip_dx/index",
    component: () => import("@/views/vip_dx/index.vue"),
    meta: {
      title: "全国电信尊享会员",
    },
  },
  {
    path: "/aito/index",
    component: () => import("@/views/aito/index.vue"),
    meta: {
      title: "AI兔会员",
    },
  },
  {
    path: "/aito/superVip",
    component: () => import("@/views/aito/superVip.vue"),
    meta: {
      title: "视听甄选会员",
    },
  },
  {
    path: "/aito/monthlyHotTopicsNTo2",
    component: () => import("@/views/aito/monthlyHotTopicsNTo2.vue"),
    meta: {
      title: "月月领会员",
    },
  },
  {
    path: "/fangXiangGan_threepay/watermark",
    component: () => import("@/views/fangXiangGan_threepay/watermark.vue"),
    meta: {
      title: "方向感",
    },
  },
  {
    path: "/fangXiangGan_threepay/orderreport",
    component: () => import("@/views/fangXiangGan_threepay/orderreport.vue"),
    meta: {
      title: "方向感下单报备页",
    },
  },
  {
    path: "/fangXiangGan_threepay/xcxExchange_link",
    component: () =>
      import("@/views/fangXiangGan_threepay/xcxExchange_link.vue"),
    meta: {
      title: "方向感",
    },
  },
  {
    path: "/aito/equityNC2",
    component: () => import("@/views/aito/equityNC2.vue"),
    meta: {
      title: "AI兔会员",
    },
  },
  {
    path: "/leaseTransMaster/reservation",
    component: () => import("@/views/leaseTransMaster/reservation.vue"),
    meta: {
      title: "租译通",
    },
  },
  {
    path: "/leaseTransMaster/successful",
    component: () => import("@/views/leaseTransMaster/successful.vue"),
    meta: {
      title: "租译通",
    },
  },
  {
    path: "/leaseTransMaster/return",
    component: () => import("@/views/leaseTransMaster/return.vue"),
    meta: {
      title: "租译通",
    },
  },
  {
    path: "/monthlyHotTopicsNTo2_qiumo",
    component: () => import("@/views/monthlyHotVipNTo2_qiumo/index.vue"),
    meta: {
      title: "随心会员",
    },
  },
  {
    path: "/monthlyHotTopicsNTo2_qiumo/activityRule",
    component: () => import("@/views/monthlyHotVipNTo2_qiumo/activityRule.vue"),
    meta: {
      title: "随心会员",
    },
  },
  {
    path: "/monthlyHotTopicsNTo2_qiumo/audiovisualSelection",
    component: () =>
      import("@/views/monthlyHotVipNTo2_qiumo/audiovisualSelection.vue"),
    meta: {
      title: "视听甄选会员",
    },
  },
  {
    path: "/recurringPayment_zhengxing/productFiling/index",
    component: () =>
      import("@/views/recurringPayment_zhengxing/productFiling/index.vue"),
    meta: {
      title: "随心会员",
    },
  },
  {
    path: "/trialSelectionMember/promotion499",
    component: () =>
      import("@/views/trialSelectionMember/promotion499/index.vue"),
    meta: {
      title: "视听甄选会员",
    },
  },
  {
    path: "/guangtong_equity_center",
    component: () => import("@/views/guangtong_equity_center/index.vue"),
    meta: {
      title: "视听甄选会员",
    },
  },
  {
    path: "/orderManipulation_GuangTong",
    component: () => import("@/views/orderManipulation_GuangTong/index.vue"),
    meta: {
      title: "会员月月领",
    },
  },
  {
    path: "/guangtong_equity_center/nC2",
    component: () => import("@/views/guangtong_equity_center/nC2.vue"),
    meta: {
      title: "视听甄选会员",
    },
  },
  {
    path: "/WuHanHeXin",
    component: () => import("@/views/WuHanHeXin/index.vue"),
    meta: {
      title: "随心会员",
    },
  },
  {
    path: "/orderManipulation_taiyue/oneFen",
    component: () => import("@/views/orderManipulation_taiyue/oneFen.vue"),
    meta: {
      title: "会员月月领",
    },
  },
  {
    path: "/orderManipulation_taiyue",
    component: () => import("@/views/orderManipulation_taiyue/index.vue"),
    meta: {
      title: "会员月月领",
    },
  },
  {
    path: "/guangtong_equity_center/yifen",
    component: () => import("@/views/guangtong_equity_center/yifen.vue"),
    meta: {
      title: "一分钱特惠会员",
    },
  },
  {
    path: "/guangtong_equity_center/linkKS",
    component: () => import("@/views/guangtong_equity_center/linkKS.vue"),
    meta: {
      title: "视听甄选会员",
    },
  },
  {
    path: "/guangtong_equity_center/uLife",
    component: () => import("@/views/guangtong_equity_center/uLife.vue"),
    meta: {
      title: "优生活会员",
    },
  },
  {
    path: "/shuadan/likeu",
    component: () => import("@/views/shuadan/likeu.vue"),
    meta: {
      title: "一分会员",
    },
  },
  {
    path: "/shuadan/qiumo1",
    component: () => import("@/views/shuadan/qiumo1.vue"),
    meta: {
      title: "三元会员",
    },
  },
  {
    path: "/shuadan/likeu1",
    component: () => import("@/views/shuadan/likeu1.vue"),
    meta: {
      title: "三元会员",
    },
  },
  {
    path: "/shuadan/dasitan",
    component: () => import("@/views/shuadan/dasitan.vue"),
    meta: {
      title: "三元会员",
    },
  },
  {
    path: "/shuadan/tiexin",
    component: () => import("@/views/shuadan/tiexin.vue"),
    meta: {
      title: "三元会员",
    },
  },
  {
    path: "/fz_taiyue",
    component: () => import("@/views/fz_taiyue/index.vue"),
    meta: {
      title: "视听甄选会员",
    },
  },
  {
    path: "/fz_taiyue/receive/login",
    component: () => import("@/views/fz_taiyue/receive/login.vue"),
    meta: {
      title: "登录",
    },
  },
  {
    path: "/fz_taiyue/receive/membership",
    component: () => import("@/views/fz_taiyue/receive/membership.vue"),
    meta: {
      title: "权益中心",
    },
  },
  {
    path: "/fz_taiyue/receive/homePage",
    component: () => import("@/views/fz_taiyue/receive/homePage.vue"),
    meta: {
      title: "泰岳会员管家",
    },
  },
  {
    path: "/chaoyouPage_20/index",
    component: () => import("@/views/chaoyouPage_20/index.vue"),
    meta: {
      title: "20元5G酷游包_随心选",
    },
  },
  {
    path: "/chaoyouPage_25/index",
    component: () => import("@/views/chaoyouPage_25/index.vue"),
    meta: {
      title: "25元5G潮游包_随心选",
    },
  },
  {
    path: "/fz_taiyue/receive/confirmreceive",
    component: () => import("@/views/fz_taiyue/receive/confirmreceive.vue"),
    meta: {
      title: "权益详情",
    },
  },
  {
    path: "/fz_taiyue/receive/record",
    component: () => import("@/views/fz_taiyue/receive/record.vue"),
    meta: {
      title: "开通/续费记录",
    },
  },
  {
    path: "/fz_taiyue/receive/pact",
    component: () => import("@/views/fz_taiyue/receive/pact.vue"),
    meta: {
      title: "业务退订",
    },
  },
  {
    path: "/fz_taiyue/receive/refund",
    component: () => import("@/views/fz_taiyue/receive/refund.vue"),
    meta: {
      title: "业务退款",
    },
  },
  {
    path: "/fz_taiyue/receive/equity",
    component: () => import("@/views/fz_taiyue/receive/equity.vue"),
    meta: {
      title: "领取记录",
    },
  },
  {
    path: "/lky_gzh/receive/vipPage",
    component: () => import("@/views/lky_gzh/receive/vipPage.vue"),
    meta: {
      title: "会员中心",
    },
  },
  {
    path: "/lky_gzh/receive/login",
    component: () => import("@/views/lky_gzh/receive/login.vue"),
    meta: {
      title: "登录",
    },
  },
  {
    path: "/lky_gzh/record/index",
    component: () => import("@/views/lky_gzh/record/index.vue"),
    meta: {
      title: "权益领取",
    },
  },
  {
    path: "/lky_gzh/record/refund",
    component: () => import("@/views/lky_gzh/record/refund.vue"),
    meta: {
      title: "退款",
    },
  },
  {
    path: "/lky_gzh/record/break",
    component: () => import("@/views/lky_gzh/record/break.vue"),
    meta: {
      title: "解约",
    },
  },
  {
    path: "/fz_taiyue/you_life",
    component: () => import("@/views/fz_taiyue/you_life.vue"),
    meta: {
      title: "优生活会员",
    },
  },
  {
    path: "/DXAnHuiLargeFlow39",
    component: () => import("@/views/DXAnHui/largeFlow39.vue"),
    meta: {
      title: "安徽电信大流量月包39元",
    },
  },
  {
    path: "/DXAnHuiLargeFlow",
    component: () => import("@/views/DXAnHui/largeFlow.vue"),
    meta: {
      title: "安徽电信大流量月包39元",
    },
  },
  {
    path: "/monthlyHotTopicsNTo2_life_huimo",
    component: () => import("@/views/monthlyHotTopicsNTo2_life/huiMo.vue"),
    meta: {
      title: "优生活会员",
    },
  },
  {
    path: "/monthlyHotTopicsNTo2_life_huimo_orange",
    component: () =>
      import("@/views/monthlyHotTopicsNTo2_life/huiMo_orange.vue"),
    meta: {
      title: "优生活会员",
    },
  },
  {
    path: "/monthlyHotTopicsNTo2_life_heyou",
    component: () => import("@/views/monthlyHotTopicsNTo2_life/heYou.vue"),
    meta: {
      title: "优生活会员",
    },
  },
  {
    path: "/orderManipulation_huimo",
    component: () => import("@/views/orderManipulation_huimo/index.vue"),
    meta: {
      title: "会员月月领",
    },
  },
  {
    path: "/orderManipulation_heyou",
    component: () => import("@/views/orderManipulation_heyou/index.vue"),
    meta: {
      title: "会员月月领",
    },
  },
  {
    path: "/hz_huimo/receive/login",
    component: () => import("@/views/hz_huimo/receive/login.vue"),
    meta: {
      title: "登录",
    },
  },
  {
    path: "/hz_huimo/receive/membership",
    component: () => import("@/views/hz_huimo/receive/membership.vue"),
    meta: {
      title: "权益中心",
    },
  },
  {
    path: "/hz_huimo/receive/homePage",
    component: () => import("@/views/hz_huimo/receive/homePage.vue"),
    meta: {
      title: "卉墨会员管家",
    },
  },
  {
    path: "/hz_huimo/receive/confirmreceive",
    component: () => import("@/views/hz_huimo/receive/confirmreceive.vue"),
    meta: {
      title: "权益详情",
    },
  },
  {
    path: "/hz_huimo/receive/record",
    component: () => import("@/views/hz_huimo/receive/record.vue"),
    meta: {
      title: "开通/续费记录",
    },
  },
  {
    path: "/hz_huimo/receive/pact",
    component: () => import("@/views/hz_huimo/receive/pact.vue"),
    meta: {
      title: "业务退订",
    },
  },
  {
    path: "/hz_huimo/receive/refund",
    component: () => import("@/views/hz_huimo/receive/refund.vue"),
    meta: {
      title: "业务退款",
    },
  },
  {
    path: "/hz_huimo/receive/equity",
    component: () => import("@/views/hz_huimo/receive/equity.vue"),
    meta: {
      title: "领取记录",
    },
  },
  {
    path: "/changXueSupreme/receive/login",
    component: () => import("@/views/changXueSupreme/receive/login.vue"),
    meta: {
      title: "登录",
    },
  },
  {
    path: "/changXueSupreme/receive/homePage",
    component: () => import("@/views/changXueSupreme/receive/homePage.vue"),
    meta: {
      title: "权益领取",
    },
  },
  {
    path: "/changXueSupreme/receive/my",
    component: () => import("@/views/changXueSupreme/receive/my.vue"),
    meta: {
      title: "我的",
    },
  },
  {
    path: "/changXueSupreme/receive/record",
    component: () => import("@/views/changXueSupreme/receive/record.vue"),
    meta: {
      title: "领取记录",
    },
  },
  {
    path: "/YunNanDX/instantDiscount",
    component: () => import("@/views/YunNanDX/instantDiscount/index.vue"),
    meta: {
      title: "立减金权益包-仅测试",
    },
  },
  {
    path: "/monthlyHotTopicsNTo2_life_tiexin",
    component: () => import("@/views/monthlyHotTopicsNTo2_life/tieXin.vue"),
    meta: {
      title: "优生活会员",
    },
  },
  {
    path: "/monthlyHotTopicsNTo2_life_dasitan",
    component: () => import("@/views/monthlyHotTopicsNTo2_life/daSitan.vue"),
    meta: {
      title: "优生活会员",
    }
  },
  {
    path: "/changxue_vip/index",
    component: () => import("@/views/changxue_vip/index.vue"),
    meta: {
      title: "畅学至尊会员",
    },
  },
  {
    path: "/qiuMoFusion",
    component: () => import("@/views/qiuMoFusion/index.vue"),
    meta: {
      title: "热门会员立即领取",
    },
  },
  {
    path: "/qiuMoFusion/LTZheJiang/firstMonth0.01",
    component: () => import("@/views/qiuMoFusion/LT/zhejiang/firstMonth0.01.vue"),
    meta: {
      title: "芒果TV会员月卡15G",
    },
  },
  {
    path: "/qiuMoFusion/LTZheJiang/videoRingtone25",
    component: () => import("@/views/qiuMoFusion/LT/zhejiang/videoRingtone25.vue"),
    meta: {
      title: "浙江联通视频彩铃钻石会员25元包",
    },
  },
  {
    path: "/qiuMoFusion/LTZheJiang/enjoy99",
    component: () => import("@/views/qiuMoFusion/LT/zhejiang/enjoy99.vue"),
    meta: {
      title: "浙江联通沃畅游99至尊会员-包月25元",
    },
  },
  {
    path: "/qiuMoFusion/DXZheJiang/specialOffer29",
    component: () => import("@/views/qiuMoFusion/DX/zhejiang/specialOffer29.vue"),
    meta: {
      title: "29元5G惠民流量包",
    },
  },
  {
    path: "/qiuMoFusion/DXZheJiang/specialOffer19",
    component: () => import("@/views/qiuMoFusion/DX/zhejiang/specialOffer19.vue"),
    meta: {
      title: "19元5G惠民流量包",
    },
  },
  {
    path: "/qiuMoFusion/DXZheJiang/specialOffer9.9",
    component: () => import("@/views/qiuMoFusion/DX/zhejiang/specialOffer9.9.vue"),
    meta: {
      title: "9.9元5G惠民流量包",
    },
  },
  {
    path: "/qiuMoFusion/YDHaiNan/5GPremiumPackage",
    component: () => import("@/views/qiuMoFusion/YD/HaiNan/5GPremiumPackage.vue"),
    meta: {
      title: "5G优享包-20元15GB",
    },
  },
  {
    path: "/qiuMoFusion/LTAnHui/cloudAcceleration20",
    component: () => import("@/views/qiuMoFusion/LT/anhui/cloudAcceleration20.vue"),
    meta: {
      title: "安徽联通20元云加速权益包",
    },
  },
  {
    path: "/qiuMoFusion/LTAnHui/videoRingtone26",
    component: () => import("@/views/qiuMoFusion/LT/anhui/videoRingtone26.vue"),
    meta: {
      title: "安徽联通26元视彩助理包",
    },
  },
  {
    path: "/qiuMoFusion/LTAnHui/cloudAccelerationDouYin26",
    component: () => import("@/views/qiuMoFusion/LT/anhui/cloudAccelerationDouYin26.vue"),
    meta: {
      title: "安徽联通26元云加速抖音组合包 ",
    },
  },
  {
    path: "/qiuMoFusion/DXAnHui/39yuan20GB",
    component: () => import("@/views/qiuMoFusion/DX/anhui/39yuan20GB.vue"),
    meta: {
      title: "安徽电信39元20GB大流量月包 ",
    },
  },
  {
    path: "/qiuMoFusion/YDHaiNan/foodCouponMember",
    component: () => import("@/views/qiuMoFusion/YD/HaiNan/foodCouponMember.vue"),
    meta: {
      title: "椰岛饭票-19.9元/月",
    },
  },
  {
    path: "/qiuMoFusion/YDGuangXi/YoukuWatch",
    component: () => import("@/views/qiuMoFusion/YD/GuangXi/YoukuWatch.vue"),
    meta: {
      title: "19.9元优酷视频随心看",
    },
  },
  {
    path: "/qiuMoFusion/DXHaiNan/29.9yuan30GB",
    component: () => import("@/views/qiuMoFusion/DX/hainan/29.9yuan30GB.vue"),
    meta: {
      title: "海南电信29.9元30GB流量权益包 ",
    },
  },
  {
    path: "/qiuMoFusion/YDHaiNan/5GEnjoymentPackage",
    component: () => import("@/views/qiuMoFusion/YD/HaiNan/5GEnjoymentPackage.vue"),
    meta: {
      title: "海南移动-5G惠享包",
    },
  },

  {
    path: "/qiuMoFusion/ALL/dx_fusion",
    component: () => import("@/views/qiuMoFusion/ALL/dx_fusion.vue"),
    meta: {
      title: "全国电信尊享会员（14.9元/月）",
    },
  },
  {
    path: "/qiuMoFusion/ALL/yd_fusion",
    component: () => import("@/views/qiuMoFusion/ALL/yd_fusion.vue"),
    meta: {
      title: "娱乐联合会员20元",
    },
  },
  {
    path: "/qiuMoFusion/DX/yunnan/assemble19.9",
    component: () => import("@/views/qiuMoFusion/DX/yunnan/assemble19.9.vue"),
    meta: {
      title: "19.9元聚合权益月包",
    },
  },
  {
    path: "/qiuMoFusion/YD/chongqing/chaoyou",
    component: () => import("@/views/qiuMoFusion/YD/chongqing/chaoyou.vue"),
    meta: {
      title: "25元5G潮游包_随心选（网约车出行）",
    },
  },
  {
    path: "/qiuMoFusion/YD/henan/suixxVip",
    component: () => import("@/views/qiuMoFusion/YD/henan/suixxVip.vue"),
    meta: {
      title: "随心选会员合约（电渠专属）",
    },
  },
  {
    path: "/qiuMoFusion/YD/henan/50Gpackage",
    component: () => import("@/views/qiuMoFusion/YD/henan/50Gpackage.vue"),
    meta: {
      title: "15元享50G流量月包12个月合约（电渠专属）",
    },
  },
  {
    path: "/qiuMoFusion/YD/henan/5Gpackage4",
    component: () => import("@/views/qiuMoFusion/YD/henan/5Gpackage4.vue"),
    meta: {
      title: "15元5G流量包-娱乐版6个月合约(电渠专属)",
    },
  },
  {
    path: "/qiuMoFusion/LT/hubei/package20",
    component: () => import("@/views/qiuMoFusion/LT/hubei/package20.vue"),
    meta: {
      title: "20元10G全国流量5G加油包",
    },
  },
  {
    path: "/qiuMoFusion/LT/sichuan/hotVip",
    component: () => import("@/views/qiuMoFusion/LT/sichuan/hotVip.vue"),
    meta: {
      title: "29元彩铃云盘权益N选2流量组合包",
    },
  },
  {
    path: "/qiuMoFusion/LT/sichuan/pandaAI",
    component: () => import("@/views/qiuMoFusion/LT/sichuan/pandaAI.vue"),
    meta: {
      title: "熊猫AI数字人权益19元包",
    },
  },
  {
    path: "/qiuMoFusion/LT/shanghai/cloudAcceleration29",
    component: () => import("@/views/qiuMoFusion/LT/shanghai/cloudAcceleration29.vue"),
    meta: {
      title: "专享29元安全管家云加速组合包 ",
    },
  },
  {
    path: "/qiuMoFusion/LT/shanghai/videoRingtone25",
    component: () => import("@/views/qiuMoFusion/LT/shanghai/videoRingtone25.vue"),
    meta: {
      title: "专享25元彩铃云盘流量组合包 ",
    },
  },
  {
    path: "/shuadan/threepay_dasitan",
    component: () => import("@/views/shuadan/threepay_dasitan.vue"),
    meta: {
      title: "三元会员",
    },
  },
  {
    path: "/shuadan/threepay_likeu1",
    component: () => import("@/views/shuadan/threepay_likeu1.vue"),
    meta: {
      title: "三元会员",
    },
  },
  {
    path: "/shuadan/threepay_tiexin",
    component: () => import("@/views/shuadan/threepay_tiexin.vue"),
    meta: {
      title: "三元会员",
    },
  },
  {
    path: "/guangtong_equity_center/uLifeRed",
    component: () => import("@/views/guangtong_equity_center/uLifeRed/index.vue"),
    meta: {
      title: "优生活会员",
    },
  },
  {
    path: "/guangtong_equity_center/uLifeRed/userAgreement",
    component: () => import("@/views/guangtong_equity_center/uLifeRed/userAgreement.vue"),
    meta: {
      title: "用户协议",
    },
  },
  {
    path: "/guangtong_equity_center/uLifeRed/customerService",
    component: () => import("@/views/guangtong_equity_center/uLifeRed/customerService.vue"),
    meta: {
      title: "在线客服",
    },
  },
  {
    path: "/guangtong_equity_center/uLifeRed/privilegeLanding",
    component: () => import("@/views/guangtong_equity_center/uLifeRed/privilegeLanding.vue"),
    meta: {
      title: "权益领取说明",
    },
  },
  {
    path: "/guangDongLenjoy/QQMusic",
    component: () => import("@/views/guangDongLenjoy/QQMusic.vue"),
    meta: {
      title: "广东联通乐享月卡会员",
    },
  },
  {
    path: "/guangDongLenjoy/joyfulUse25",
    component: () => import("@/views/guangDongLenjoy/joyfulUse25.vue"),
    meta: {
      title: "广东联通欢用25元10GB国内流量",
    },
  },
  {
    path: "/fusion/LTVideoRingtone",
    component: () => import("@/views/fusion/LTVideoRingtone/index.vue"),
    meta: {
      title: "联通视频彩铃",
    },
  },
  {
    path: "/fusion/LTVideoRingtone/zhejiang/videoRingtone25",
    component: () => import("@/views/fusion/LTVideoRingtone/zhejiang/videoRingtone25.vue"),
    meta: {
      title: "浙江联通视频彩铃钻石会员25元包",
    },
  },
  {
    path: "/fusion/LTVideoRingtone/anhui/cloudAcceleration20",
    component: () => import("@/views/fusion/LTVideoRingtone/anhui/cloudAcceleration20.vue"),
    meta: {
      title: "安徽联通20元云加速权益包",
    },
  },
  {
    path: "/fusion/LTVideoRingtone/anhui/videoRingtone26",
    component: () => import("@/views/fusion/LTVideoRingtone/anhui/videoRingtone26.vue"),
    meta: {
      title: "安徽联通26元视彩助理包",
    },
  },
  {
    path: "/fusion/LTVideoRingtone/anhui/cloudAccelerationDouYin26",
    component: () => import("@/views/fusion/LTVideoRingtone/anhui/cloudAccelerationDouYin26.vue"),
    meta: {
      title: "安徽联通26元云加速抖音组合包 ",
    },
  },
  {
    path: "/fusion/LTVideoRingtone/shanghai/cloudAcceleration29",
    component: () => import("@/views/fusion/LTVideoRingtone/shanghai/cloudAcceleration29.vue"),
    meta: {
      title: "专享29元安全管家云加速组合包 ",
    },
  },
  {
    path: "/fusion/LTVideoRingtone/shanghai/videoRingtone25",
    component: () => import("@/views/fusion/LTVideoRingtone/shanghai/videoRingtone25.vue"),
    meta: {
      title: "专享25元彩铃云盘流量组合包 ",
    },
  },
  {
    path: "/fusion/LTVideoRingtone/sichuan/hotVip",
    component: () => import("@/views/fusion/LTVideoRingtone/sichuan/hotVip.vue"),
    meta: {
      title: "29元彩铃云盘权益N选2流量组合包",
    },
  },
  {
    path: "/leaseTransMaster/blue_reservation",
    component: () => import("@/views/leaseTransMaster/blue_reservation.vue"),
    meta: {
      title: "租译通",
    },
  },
  {
    path: "/leaseTransMaster/blue_successful",
    component: () => import("@/views/leaseTransMaster/blue_successful.vue"),
    meta: {
      title: "租译通",
    },
  },
  {
    path: "/fusion/LTVideoRingtone/liaoning/microdrama25_version",
    component: () => import("@/views/fusion/LTVideoRingtone/liaoning/microdrama25_version.vue"),
    meta: {
      title: "辽宁联通视频彩铃应用型产品尊享微剧版25元",
    },
  },
  {
    path: "/fusion/LTVideoRingtone/liaoning/likeListening",
    component: () => import("@/views/fusion/LTVideoRingtone/liaoning/likeListening.vue"),
    meta: {
      title: "21元联通爱听（含云盘）月包（辽宁）",
    },
  },
  {
    path: "/fusion/LTVideoRingtone/liaoning/cloudAcceleration",
    component: () => import("@/views/fusion/LTVideoRingtone/liaoning/cloudAcceleration.vue"),
    meta: {
      title: "辽宁联通云加速",
    },
  },
  {
    path: "/zaomitang",
    component: () => import("@/views/zaomitang/index.vue"),
    meta: {
      title: "枣米糖",
    },
  },
  {
    path: "/shuadan/huimo",
    component: () => import("@/views/shuadan/huimo.vue"),
    meta: {
      title: "三元会员",
    },
  },
  {
    path: "/shuadan/heyou",
    component: () => import("@/views/shuadan/heyou.vue"),
    meta: {
      title: "三元会员",
    },
  },
  {
    path: "/report",
    component: () => import("@/views/report/index.vue"),
    meta: {
      title: "权益超市铂金会员（家庭流量版）",
    },
  },
  

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
