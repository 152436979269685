import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
import sxt from './modules/sxt';
import memberBenefits from "./modules/memberBenefits";
import carOwner_zhengxing from "./modules/carOwner_zhengxing";
import taiyue from "./modules/taiyue";
import lky from "./modules/lky";
import changXueSupreme from './modules/changXueSupreme';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    sxt,
    memberBenefits,
    carOwner_zhengxing,
    taiyue,
    lky,
    changXueSupreme
  },
  getters,
})
