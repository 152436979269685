import storage, { removeToken } from "@/utils/auth";
import { getUserInfo } from "@/api/changXueSupreme/index.js";
import router from "@/router";

const getDefaultState = () => ({
  productNo: "20997",
  token: storage.token || "",
  info: {},
});

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN(state, status) {
    Object.assign(state, status);
    storage.token = state.token;
  },
  SET_USER_INFO(state, status) {
    state.info = status;
  },
};

const actions = {
  async getUserInfo({ state, commit }) {
    console.log(state)
    try {
      const res = await getUserInfo(state.token, {productNo: '20997' });
      commit("SET_USER_INFO", {...res.data, success: res.success});
    } catch (error) {
      router.replace("/changXueSupreme/receive/login");
    }
  },
  logOut({ commit }) {
    // commit("SET_USER_INFO", {phone: ""});
    removeToken();
  }
};

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations,
  actions,
};
