import storage, { removeToken } from "@/utils/auth";
import { getUser } from "@/api/lky_gzh/index.js";
import router from "@/router";

const getDefaultState = () => ({
  token: storage.token || "",
  // lky_phonenumber: storage.lky_phonenumber || "",
  user_info: storage.user_info || {
    phone_number: "",
    available_vip: [],
    exchanged_items: [],
  },
  wechat_open_id: storage.wechat_open_id || "",
});

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN(state, status) {
    state.token = status;
    storage.token = state.token;
  },
  SET_WECHAT_OPEN_ID(state, status) {
    state.wechat_open_id = status;
    storage.wechat_open_id = state.wechat_open_id;
  },
  // SET_PHONE_NUMBER(state, status) {
  //   state.lky_phonenumber = status;
  //   storage.lky_phonenumber = state.lky_phonenumber;
  //   console.log(state)
  // },
  SET_USER_INFO(state, status) {
    state.user_info = status;
    storage.user_info = state.user_info;
  },
};

// const actions = {
//   async getUserInfo({ state, commit }) {
//     try {
//       const res = await getUser(state.token);
//       console.log('res.user_info====',res.user_info)
//       if (res.code == 0 ) {
//         commit("SET_USER_INFO", res.user_info);
//       }
//       return res; 
//     } catch (error) {
//       router.push({
//         path: "/lky_gzh/receive/vipPage"
//         // path: "/lky_gzh/receive/vipPage"
//       });
//     }
//   },
// };

const actions = {
  async getUserInfo({ state, commit }) {
    try {
      const res = await getUser(state.token);
      console.log('res.user_info====', res.user_info);
      if (res.code == 0) {
        commit("SET_USER_INFO", res.user_info);
      }
      return res;
    } catch (error) {
      const currentPath = router.currentRoute.path;
      const targetPath = "/lky_gzh/receive/vipPage";

      if (currentPath !== targetPath) {
        router.push({
          path: targetPath
        });
      }
    }
  },
};

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations,
  actions,
};