import request from "@/utils/request";
const ip = "https://as.qiumo.net.cn/marketapi";

// 获取验证码
export const loginSms = (data) =>
  request({
    url: "/xiaowo/equity/ex/loginSms",
    method: "post",
    ipBaseURL: ip,
    data,
  });

// 登录
export const login = (data) =>
  request({
    url: "/xiaowo/equity/ex/login",
    method: "post",
    ipBaseURL: ip,
    data,
  });

// 权益列表
export const equityList = (token) =>
  request({
    url: "/xiaowo/equity/equityList",
    method: "get",
    ipBaseURL: ip,
    headers: {
      "TOKEN-AUTH": token,
    },
  });

// 领取权益
export const exchange = (token, data) =>
  request({
    url: "/xiaowo/equity/exchange",
    method: "post",
    ipBaseURL: ip,
    data,
    headers: {
      "TOKEN-AUTH": token,
    },
  });

// 获取用户信息
export const getUserInfo = (token, data) =>
  request({
    url: "/xiaowo/equity/getUserInfo",
    method: "post",
    ipBaseURL: ip,
    data,
    headers: {
      "TOKEN-AUTH": token,
    },
  });

  // 领取记录
  export const getRecord = (token, productNo) =>
  request({
    url: `/xiaowo/equity/equityReceiveList/${productNo}`,
    method: "get",
    ipBaseURL: ip,
    headers: {
      "TOKEN-AUTH": token,
    },
  });