import request from '@/utils/request';
import { setAppName } from "@/utils/common";
const appName = setAppName();
// const appName = "huanxinxiang-zhengxing";

// 发送短信
export const code = (data) => request({
  url: '/huanxinxiang-service/v1/code/',
  method: 'post',
  headers: {
    "App-Name": appName,
  },
  data,
});

// 支付宝签约
export const codeDlipayPay = (data) => request({
  url: '/huanxinxiang-service/v1/alipay-sms-pay/',
  method: 'post',
  headers: {
    "App-Name": appName,
  },
  data,
});


// 登录
export const login = (data) => request({
  url: '/huanxinxiang-service/v1/login/',
  method: 'post',
  headers: {
    "App-Name": appName,
  },
  data,
});

// 支付宝登录
export const alipayLogin = (data) =>
  request({
    url: "/huanxinxiang-service/v1/alipay-h5-login/",
    method: "post",
    headers: {
      "App-Name": appName,
    },
    data
  });

export const user = (Token) => request({
  url: '/huanxinxiang-service/v1/get-user/',
  method: 'post',
  headers: {
    "App-Name": appName,
    Token,
  },
});

// 权益列表
export const equityList = (data) => request({
  url: '/huanxinxiang-service/v1/get-item/',
  method: 'post',
  headers: {
    "App-Name": appName,
  },
  data,
});

// 记录
export const record = (Token, data) =>
  request({
    url: "/huanxinxiang-service/v1/get-record/",
    method: "post",
    headers: {
      Token,
      "App-Name": appName,
    },
    data,
  });

export const equityReceive = (Token, data) =>
  request({
    url: "/huanxinxiang-service/v1/buy-item/",
    method: "post",
    headers: {
      Token,
      "App-Name": appName,
    },
    data,
});
