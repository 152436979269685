import axios from 'axios';
import { Notify } from 'vant';

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  withCredentials: false,
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    if (!!config.ipBaseURL) {
      config.baseURL = config.ipBaseURL;
    }
    return config;
  },
  (error) => {
    console.log(error); // for debug
    return Promise.reject(error);
  },
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    const {
      code,
      message,
      data,
      msg
    } = response.data;
    if (response.headers['content-disposition']) {
      return response;
    }
    //系统异常
    if(code === 5000 || code == 500){
      Notify({ type: 'danger', message: message || msg,duration:5000 })
      return response.data;
    }

    if (code === undefined) {
      return response.data;
    }

    return response.data;
  },
  (error) => {

    const { response } = error;
    console.log(response)
    if (response) {
      const { code, message } = response.data || {};

      const err = new Error(message || 'Response Error');
      err.code = code;

      return Promise.reject(err);
    }
    // Notify({ type: 'danger', message: 'Response Error' });
    return Promise.reject(error);
  },
);

export default service;
