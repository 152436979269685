import request from "@/utils/request";
import { setAppName } from "@/utils/common";
const ipBaseURL = "https://test-huanxinxiang.ai-me.cn/huanxinxiang-service/";
const appName = setAppName();
// const appName = "huanxinxiang-zhengxing";



// 发送短信
export const code = (data) => request({
    url: '/huanxinxiang-service/v1/code/',
    method: 'post',
    headers: {
      "App-Name": "huanxinxiang-laikeyou",
    },
    data,
  });
  
  // 短信验证码登录
  export const login = (data) => request({
    url: '/huanxinxiang-service/v1/login/',
    method: 'post',
    headers: {
      "App-Name": "huanxinxiang-laikeyou",
    },
    data,
  });


// 根据openId登录
export const wechatLogin = (data) =>
    request({
        url: "/huanxinxiang-service/v1/wechat-login/",
        method: "post",
        headers: {
            "App-Name": "huanxinxiang-laikeyou",
        },
        // ipBaseURL,
        data,
    });

// 获取微信openid
export const getWechatopenid = (data) =>
    request({
        url: "/huanxinxiang-service/v1/get-wechat-open-id/",
        method: "post",
        headers: {
            "App-Name": "huanxinxiang-laikeyou",
        },
        // ipBaseURL,
        data,
    });



// 获取用户信息 
export const getUser = (token) => request({
  url: '/huanxinxiang-service/v1/get-user/',
  method: 'post',
  headers: {
    "App-Name": "huanxinxiang-laikeyou",
    Token : token,
  },
});



//-------------------------------------//
// 权益列表
export const equityList = (data) => request({
  url: '/huanxinxiang-service/v1/get-item/',
  method: 'post',
  headers: {
    "App-Name": "huanxinxiang-laikeyou",
  },
  data,
});
// 领取权益
export const equityReceive = (Token, data) =>
  request({
    url: "/huanxinxiang-service/v1/buy-item/",
    method: "post",
    headers: {
      Token,
      "App-Name": "huanxinxiang-laikeyou",
    },
    data,
});



// 可退款订单
export const getRefundable = (Token) =>
  request({
    url: "/huanxinxiang-service/v1/get-refundable/",
    method: "post",
    headers: {
      Token,
      "App-Name": "huanxinxiang-laikeyou",
    },
});
// 退款 
export const refundSingle = (Token, data) =>
  request({
    url: "/huanxinxiang-service/v1/refund-single/",
    method: "post",
    headers: {
      Token,
      "App-Name": "huanxinxiang-laikeyou",
    },
    data,
});



// 记录
export const record = (Token, data) =>
  request({
    url: "/huanxinxiang-service/v1/get-record/",
    method: "post",
    headers: {
      Token,
      "App-Name": "huanxinxiang-laikeyou",
    },
    data,
});
// 发起解约
export const alipayUnsign = (Token, data) =>
  request({
    url: "/huanxinxiang-service/v1/alipay-unsign/",
    method: "post",
    headers: {
      Token,
      "App-Name": "huanxinxiang-laikeyou",
    },
    data,
});